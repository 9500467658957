import React, { PureComponent } from 'react'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Terminal, Feather } from 'react-feather'

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import IconContainer from '../../components/IconContainer'
import Wrapper from '../../components/Wrapper'

const Container = styled(Box)`
  border-bottom: ${themeGet('colors.dark.0')} solid 1px;
  font-size: ${themeGet('fontSizes.1')};
  position: relative;

  h4 {
    margin: 0;
    font-size: ${themeGet('fontSizes.2')};
  }

  p {
    color: ${themeGet('colors.contrast.4')};
    margin: 1em auto;
    max-width: 24rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default class Services extends PureComponent {
  render() {
    return (
      <Container>
        <Wrapper>
          <Grid gap="2em" py={[4, 4, 5]} width={320}>
            <Box>
              <Flex
                alignItems="center"
                flexDirection={['row', 'row', 'row', 'column']}
                justifyContent={['start', 'start', 'start', 'center']}
                mb={[-2, -2, -2, 0]}
                mx="auto"
                lineHeight={0}
                maxWidth="24rem"
              >
                <IconContainer
                  mb={[0, 0, 0, 2]}
                  mr={[2, 2, 2, 0]}
                  height={['1.75em', '1.75em', '1.75em', '3.5em']}
                  width={['1.75em', '1.75em', '1.75em', '3.5em']}
                >
                  <Terminal />
                </IconContainer>
                <h4>Engineering</h4>
              </Flex>
              <p>
                Level Studios Design specialise in creating robust, responsive and mobile-ready applications and digital products,
                helping you reach millions of people worldwide.
              </p>
              <p>
                Our experience spans a range of different projects from concept right through to delivery, deployment and maintenance.
              </p>
            </Box>
            <Box>
              <Flex
                alignItems="center"
                flexDirection={['row', 'row', 'row', 'column']}
                justifyContent={['start', 'start', 'start', 'center']}
                mb={[-2, -2, -2, 0]}
                mx="auto"
                lineHeight={0}
                maxWidth="24rem"
              >
                <IconContainer
                  mb={[0, 0, 0, 2]}
                  mr={[2, 2, 2, 0]}
                  height={['1.75em', '1.75em', '1.75em', '3.5em']}
                  width={['1.75em', '1.75em', '1.75em', '3.5em']}
                >
                  <Feather />
                </IconContainer>
                <h4>Design</h4>
              </Flex>
              <p>
                We bring your product vision to life using tools like{' '}
                <a href="//facebook.github.io/react-native/" target="blank" rel="nofollow">
                  React Native,
                </a>{' '}
                <a href="//rubyonrails.org/" target="blank" rel="nofollow">
                  Ruby on Rails,
                </a>
                and{' '}
                <a href="//www.python.org/" target="blank" rel="nofollow">
                  Python
                </a>, allowing us to create a unique online identity for your business or idea.
              </p>
              <p>
                Our speciality is web and mobile design, with a particular focus on MVP and SaaS applications for startups and organisations.
              </p>
            </Box>
          </Grid>
        </Wrapper>
      </Container>
    )
  }
}
